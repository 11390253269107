import { useEffect, useState } from 'react';

import { getVideoPlayer, getVideoTime } from '../Api/video';
import {
  VIDEO_DOCUMENT_WRITE_HTML_DEFAULT_STRING,
  VIDEO_HEAD_HTML_DEFAULT_STRING,
} from '../Constants';
import { useAppSelector } from '../Redux/store';
import { IVideo, IVideoDvrInfo } from '../Types';
import { DEFAULT_VIDEO_CUT_BEFORE, DEFAULT_VIDEO_SEGMENT } from '../Utils';

export const useVideo = (video: IVideo) => {
  const token = useAppSelector(state => state.login.token.access_token);
  const matchId = useAppSelector(state => state.match.value.id);

  const [key, setKey] = useState<number>(0);
  const [html, setHtml] = useState<string>('');

  useEffect(() => {
    if (token) {
      setKey(prevState => prevState + 1);

      getVideoTime(token, matchId, video.faceOffTime ? video.faceOffTime : video.time)
        .then(res => {
          const time = res.data.videoTime;
          getVideoPlayer({
            token,
            matchId,
            time: 0,
            videoType: 'segment',
            videoStartTime: time - DEFAULT_VIDEO_CUT_BEFORE,
            videoEndTime: time + DEFAULT_VIDEO_SEGMENT,
          })
            .then(res => {
              setHtml(res.data);
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, video]);

  return {
    key,
    html,
  };
};

const getCorrectVideoPart = (videoStreamParts: IVideoDvrInfo[], videoTime: number) => {
  if (videoStreamParts.length === 1) {
    return { streamPart: videoStreamParts[0], seekTime: videoTime };
  }

  const firstStreamPart = videoStreamParts.reduce(
    (minObj, currentObj) => (currentObj.utc_start < minObj.utc_start ? currentObj : minObj),
    videoStreamParts[0],
  );

  const videoEventTime = firstStreamPart.utc_start + videoTime * 1000;

  const streamPart = videoStreamParts.find(videoStreamPart => {
    const { utc_start, utc_end, live } = videoStreamPart;

    const isBetweenStartAndEnd = utc_start <= videoEventTime && utc_end >= videoEventTime;
    const isInLivePart = utc_start <= videoEventTime && live;

    return isBetweenStartAndEnd || isInLivePart;
  });

  const seekTime = streamPart ? (videoEventTime - streamPart.utc_start) / 1000 : 0;

  return { streamPart, seekTime };
};

export const useVideoDVR = (video: IVideo) => {
  const token = useAppSelector(state => state.login.token.access_token);
  const { dvrChannels, dvrId } = useAppSelector(state => state.video.value);

  const [key, setKey] = useState<number>(0);
  const [html, setHtml] = useState<string>('');
  const [errorEvent, setErrorEvent] = useState(false);

  useEffect(() => {
    const videoStreamParts = dvrChannels ? Object.values(dvrChannels)[0] : undefined;

    if (token && videoStreamParts && videoStreamParts.length > 0) {
      setKey(prevState => prevState + 1);

      const { streamPart, seekTime } = getCorrectVideoPart(videoStreamParts, video.videoTime);

      if (streamPart) {
        const dvrPlayer = getPlayerDvr(
          dvrId ?? '',
          streamPart.hls,
          streamPart.live,
          seekTime - DEFAULT_VIDEO_CUT_BEFORE,
        );
        setHtml(getVideoFrameHtml(dvrPlayer));
        if (errorEvent) {
          setErrorEvent(false);
        }
      } else {
        setErrorEvent(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, video, dvrChannels, dvrId]);

  return {
    key,
    html,
    errorEvent,
  };
};

const getPlayerDvr = (videoId: string, videoUrl: string, isLive: boolean, seekTime: number) => `
  LBXPlayerBPA.destroy({
    dom_id: 'lbx'
  });
  
  LBXPlayerBPA.dvr({
    dom_id: 'lbx',
    poster: '//hokej.cz/images/elh-live-bg.jpg',
    video: '${videoId}',
    autoplay: true,
    textEnable: true,
    controls: {
      extraControls: false
    },
    playlist: [
      {
        playlistIndex: 1,
        url: '${videoUrl}',
        dvr: ${isLive},
        videoType: 'full',
        seekTime: ${seekTime},
      }
    ]
  });
`;

const getVideoFrameHtml = (videoPlayerHtmlString: string) => `
  <!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
  <html>
    ${VIDEO_HEAD_HTML_DEFAULT_STRING}
    <body>
      <div id="lbx"></div>
      ${VIDEO_DOCUMENT_WRITE_HTML_DEFAULT_STRING}
      <script type="text/javascript">
        ${videoPlayerHtmlString}
      </script>
    </body>
  </html>
`;
