export const IVideoStatus = {
  before: 'before',
  live: 'live',
  end: 'end',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IVideoStatus = (typeof IVideoStatus)[keyof typeof IVideoStatus];

export interface IListState {
  data: IListMatch[];
}

export interface IListMatch {
  awayTeam: IListTeam;
  homeTeam: IListTeam;
  date: string;
  id: string;
  leagueId: string;
  leagueName: string;
}

export interface IListTeam {
  name: string;
  shortcut: string;
}

export interface IMatchDetailState {
  data: IMatch;
}

export interface IMatch {
  awayTeam: ITeam;
  homeTeam: ITeam;
  faceOff: IFaceOff[];
  id: string;
  lastModification: string;
  lastSynchronisation: string;
  league: string;
  length: number;
  round: string;
  season: string;
  shot: IShot[];
  goal: IGoal[];
  game: IOvertimeInfo;
  momentum: IMomentum[];
  video: IVideoStatus;
}

export interface ICoordinates {
  x: number;
  y: number;
}

export interface ITeam {
  id: string;
  name: string;
  shortName: string;
  shortcut: string;
  gameState: IGameState[];
  roster: IPlayer[];
  score: number;
}

export interface IGameState {
  start: number;
  end: number;
  state: IState;
  type: string;
  hasGoalie: boolean;
}

export type IState = '5:5' | '4:4' | '3:3' | '6:5' | '5:6' | IPPState | ISHState;

export type IPPState = '5:4' | '5:3' | '4:3' | '6:4' | '6:3';

export type ISHState = '4:5' | '3:5' | '3:4' | '4:6' | '3:6';

export interface IInterval {
  start: number;
  end: number;
}

export interface IBasePlayer {
  id: string;
  jersey: string;
  name: string;
  surname: string;
}

export interface IPlayer extends IBasePlayer {
  position: IPlayerPosition;
  stick: IPlayerStick;
  timeOnIce: ITimeOnIce;
  lineUp: IPlayerLineUp;
}

export type IPlayerPosition = 'forward' | 'backward' | 'goalkeeper';

export type IPlayerStick = 'left' | 'right';

export interface ITimeOnIce {
  toi: number;
  shifts: IShift[];
}

export type IShift = [
  {
    from: number;
    to: number;
  },
];

export interface IPlayerLineUp {
  line: number;
  position: 'LD' | 'RD' | 'LW' | 'CE' | 'RW';
}

export interface IFaceOff {
  location: IFaceOffLocation;
  time: number;
  videoTime: number;
  loserId: { id: string };
  winnerId: { id: string };
}

export type IFaceOffLocation = 'HDL' | 'HDR' | 'ADR' | 'ADL' | IFaceOffCenterLocation;

export type IFaceOffCenterLocation = 'C' | 'HBL' | 'HBR' | 'ABR' | 'ABL';

export interface IShot {
  attackersCount: number | null;
  blocked: boolean;
  blockerId: string | null;
  defendersCount: number | null;
  deflected: boolean;
  forecheck: boolean;
  gateZone: IGateZone;
  goalkeeperId: string | null;
  inSlot: boolean;
  oddManRush: boolean;
  oneTimer: boolean;
  penaltyShot: boolean;
  playerId: string;
  quickAttack: boolean;
  rebound: boolean;
  result: IShotResult;
  saveinterface: string | null;
  screeningPlayer: boolean;
  shotDanger: IShotDanger;
  time: number;
  videoTime: number;
  x: number;
  xG: number;
  y: number;
}

export type IShotDanger = 'HD' | 'MD' | 'LD';

export interface IShotsByTeam {
  home: IShot[];
  away: IShot[];
}

export type IShotResult = 'saved' | 'missed' | 'post' | 'deflected' | 'goal';

export type IGateZone = 'bl' | 'br' | 'ul' | 'ur' | 'ce' | 'fh';

export interface ITeamPowerPlayTime {
  home: number;
  away: number;
}

export interface IGoal {
  playerId: string;
  time: number;
  assist1: string;
  assist2: string;
}

export interface IOvertimeInfo {
  overtimeCount: number;
  overtimeLength: number;
  peoplesCountInOvertime: number;
}

export interface IMomentum {
  momentum: number;
  time: number;
}
