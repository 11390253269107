import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Buttons from '../04-Micros/Buttons';
import { useGetMatchDetail } from '../../Hooks';
import { setMatch } from '../../Redux/match';
import { clearPendingPickedPlayers, clearPickedPlayers } from '../../Redux/pickedPlayers';
import { useAppDispatch } from '../../Redux/store';
import { formatMatchDetails, setToSession } from '../../Utils';

export interface IMatchInfo {
  matchId: string;
  homeTeam: string;
  awayTeam: string;
  leagueName: string;
  matchDate: string;
  disabled?: boolean;
}

export interface IMatchDetails {
  homeScore: number;
  awayScore: number;
  dataTime: string;
  dataChecked: string;
}

const MatchInfo: FC<IMatchInfo> = ({
  matchId,
  homeTeam,
  awayTeam,
  leagueName,
  matchDate,
  disabled,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<IMatchDetails>();
  const { currentMatch } = useGetMatchDetail(matchId, false, true);

  useEffect(() => {
    if (!disabled && currentMatch) {
      setDetails(formatMatchDetails(currentMatch));
    }
  }, [currentMatch, disabled]);

  const handleSelect = () => {
    if (currentMatch) {
      dispatch(clearPickedPlayers());
      dispatch(clearPendingPickedPlayers());
      dispatch(setMatch(currentMatch));
      setToSession('currentMatch', currentMatch.id);
    }
    navigate('/overview');
  };

  return (
    <div className='MatchInfo'>
      <p className='MatchInfo-league'>{leagueName}</p>
      <div className='MatchInfo-info'>
        <div className='MatchInfo-teams'>
          <div className='MatchInfo-teams--team'>
            <img src={require(`../../Assets/logos/${homeTeam}.png`)} alt='team logo' />
            <p>{homeTeam}</p>
          </div>
          <div className='MatchInfo-teams--score'>
            <p>{details ? `${details.homeScore}:${details.awayScore}` : '0:0'}</p>
          </div>
          <div className='MatchInfo-teams--team'>
            <p>{awayTeam}</p>
            <img src={require(`../../Assets/logos/${awayTeam}.png`)} alt='team logo' />
          </div>
        </div>
        <div className='MatchInfo-data'>
          <div className='MatchInfo-data--stats'>
            <p>
              Začátek zápasu: <span>{matchDate}</span>
            </p>
            <p>
              Data k času: <span>{details ? details.dataTime : '-'}</span>
            </p>
          </div>
          <Buttons
            title={details ? 'Data zápasu' : 'Zápas nezačal'}
            color='background'
            disabled={!details}
            type='outlined'
            onClick={handleSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default MatchInfo;
