import { FC, useEffect } from 'react';

import { useVideo } from '../../Hooks/useVideo';
import { IVideo } from '../../Types';

const VIDEO_IFRAME_ID = 'video-file-iframe';

interface IProps {
  video: IVideo;
}

const VideoFrame: FC<IProps> = ({ video }) => {
  const { key, html } = useVideo(video);

  useEffect(() => {
    const setIframeContent = () => {
      const currentIframe = document.getElementById(VIDEO_IFRAME_ID) as HTMLIFrameElement;

      const iframeDocument = currentIframe.contentDocument;
      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(html || '');
        iframeDocument.close();
      }
    };

    setIframeContent();
  }, [key, html]);

  return (
    <iframe
      key={key}
      id={VIDEO_IFRAME_ID}
      name={VIDEO_IFRAME_ID}
      title='videoplayer'
      width='100%'
      height='100%'
      allowFullScreen
      style={{ border: 0 }}
    ></iframe>
  );
};

export default VideoFrame;
