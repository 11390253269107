import { FC, useEffect, useRef, useState } from 'react';

import { IGameState, IGoal, IIceTimeOptions, IIceTimePlayer } from '../../Types';
import {
  filterGameStates,
  formatSeconds,
  getColorOnTimeline,
  getPlayerToiInGameType,
  getPositionOnTimeline,
  getShiftsByState,
  prepareShifts,
} from '../../Utils';
import IceTimeShift from './IceTimeShift';

interface IProps {
  player: IIceTimePlayer;
  gameStates: IGameState[];
  options: IIceTimeOptions;
  details?: boolean;
  teamGoals: IGoal[];
  opponentGoals: IGoal[];
}

const IceTimeRow: FC<IProps> = ({
  player,
  gameStates,
  options,
  teamGoals,
  opponentGoals,
  details,
}) => {
  const [timelineWidth, setTimelineWidth] = useState<number>(0);
  const timelineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (timelineRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (timelineRef.current!.offsetWidth !== timelineWidth) {
          setTimelineWidth(timelineRef.current!.offsetWidth);
        }
      });

      resizeObserver.observe(timelineRef.current);

      return function cleanup() {
        resizeObserver.disconnect();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineRef.current]);

  const filteredShifts = player.timeOnIce.shifts
    ? prepareShifts(player.timeOnIce.shifts, options)
    : [];

  const filteredGameStates = gameStates.filter(filterGameStates);

  return (
    <div className='IceTimeRow'>
      <div className='IceTimeRow-player'>
        <p>
          {player.jersey} {player.surname}
        </p>
        <div className='IceTimeRow-player--stats'>
          <div className='IceTimeRow-player--statBox'>
            {formatSeconds(player.metrics ? player.metrics.TOI : 0)}
          </div>
          <div className='IceTimeRow-player--statBox'>
            {formatSeconds(getPlayerToiInGameType(gameStates, filteredShifts, 'PP', options))}
          </div>
          <div className='IceTimeRow-player--statBox'>
            {formatSeconds(getPlayerToiInGameType(gameStates, filteredShifts, 'SH', options))}
          </div>
        </div>
      </div>
      <div className='IceTimeRow-timeline' ref={timelineRef}>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        <span className='IceTimeRow-timeline--block'></span>
        {getShiftsByState(filteredShifts, filteredGameStates).map((shift, index) => {
          return (
            <IceTimeShift
              key={index}
              teamGoals={teamGoals}
              opponentGoals={opponentGoals}
              playerId={player.id}
              details={details}
              shift={shift}
              options={options}
              timelineWidth={timelineWidth}
            />
          );
        })}
        {details &&
          filteredGameStates.map((state, index) => {
            return (
              <div
                key={index}
                className='IceTimeRow-state'
                style={{
                  ...getPositionOnTimeline(state, timelineWidth),
                  ...getColorOnTimeline(state),
                }}
              ></div>
            );
          })}
      </div>
    </div>
  );
};

export default IceTimeRow;
