import { Dialog } from '@mui/material';
import { useState } from 'react';
import { useTimeout } from 'usehooks-ts';

import Buttons from '../04-Micros/Buttons';
import VideoFrame from '../04-Micros/VideoFrame';
import VideoFrameDVR from '../04-Micros/VideoFrameDVR';
import VideoHeader from '../04-Micros/VideoHeader';
import VideoPagination from '../04-Micros/VideoPagination';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '../../Icons';
import { useAppDispatch, useAppSelector } from '../../Redux/store';
import { setActiveVideo, toggleVideo } from '../../Redux/video';
import { IVideoStatus } from '../../Types';

const VideoModal = () => {
  const modal = useAppSelector(state => state.video.value);
  const videoStatus = useAppSelector(state => state.match.value.video);
  const dispatch = useAppDispatch();

  const [showControls, setShowControls] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  useTimeout(() => setShowControls(false), showControls && !hover ? 3000 : null);

  const handleClose = () => {
    dispatch(toggleVideo());
  };

  const handlePrev = () => {
    dispatch(setActiveVideo(modal.active - 1));
  };

  const handleNext = () => {
    dispatch(setActiveVideo(modal.active + 1));
  };

  return (
    <Dialog
      className='VideoModal'
      open={modal.state}
      onClose={handleClose}
      maxWidth={'lg'}
      fullWidth
    >
      <VideoHeader {...modal.videos[modal.active]} />
      <div
        className='VideoModal-video'
        onMouseEnter={() => {
          setHover(true);
          setShowControls(true);
        }}
        onMouseLeave={() => setHover(false)}
      >
        {showControls && modal.active > 0 && (
          <div className='VideoModal-controls left' onClick={handlePrev}>
            <ArrowCircleLeftIcon height={60} width={60} />
          </div>
        )}
        {showControls && modal.active < modal.videos.length - 1 && (
          <div className='VideoModal-controls right' onClick={handleNext}>
            <ArrowCircleRightIcon height={60} width={60} />
          </div>
        )}
        {videoStatus === IVideoStatus.live ? (
          <VideoFrameDVR video={modal.videos[modal.active]} />
        ) : (
          <VideoFrame video={modal.videos[modal.active]} />
        )}
      </div>
      <VideoPagination videos={modal.videos} />
      <div className='VideoModal-footer'>
        <Buttons
          title='Zavřít'
          type='outlined'
          color='white'
          maxWidth={128}
          onClick={handleClose}
        />
      </div>
    </Dialog>
  );
};

export default VideoModal;
