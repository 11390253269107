import {
  IBasePlayer,
  IFaceOff,
  IMatch,
  IPlayer,
  IShot,
  IShotResult,
  IVideo,
  IVideoDvrRecord,
  IVideoDvrResponse,
  IVideoTimes,
} from '../Types';
import { getPlayerFaceOff } from './faceOff';
import { getGameStateByTime, getTeamsGameStates } from './matches';
import { getPlayerShots } from './shots';

export const getShotType = (result: IShotResult) => {
  switch (result) {
    case 'goal':
      return 'Gól';
    case 'saved':
      return 'Chycená střela';
    case 'deflected':
      return 'Zablokovaná střela';
    default:
      return 'Střela mimo';
  }
};

export const formatShotVideo = (
  basePlayer: IBasePlayer,
  videoTimes: IVideoTimes,
  match: IMatch,
): IVideo[] => {
  const { id, jersey, name, surname } = basePlayer;
  const { time, videoTime } = videoTimes;
  const shot = match.shot.find(item => item.playerId === id && Math.abs(item.time - time) <= 3);

  return [
    {
      player: { jersey, name, surname },
      time,
      videoTime,
      type: getShotType(shot!.result),
      gameState: getGameStateByTime(getTeamsGameStates(id, match), time),
      danger: shot?.shotDanger,
      xG: shot?.xG,
    },
  ];
};

export const formatFaceOffVideo = (
  basePlayer: IBasePlayer,
  videoTimes: IVideoTimes,
  match: IMatch,
  winner: boolean,
): IVideo[] => {
  const { id, jersey, name, surname } = basePlayer;
  const { time, videoTime } = videoTimes;

  return [
    {
      player: { jersey, name, surname },
      time,
      videoTime,
      faceOffTime: time + 1,
      type: `${winner ? 'Vyhrané' : 'Prohrané'} vhazování`,
      gameState: getGameStateByTime(getTeamsGameStates(id, match), time),
    },
  ];
};

export const getPlayerShotVideos = (player: IPlayer, array: IShot[], match: IMatch) => {
  const { id, jersey, name, surname } = player;
  const shots = getPlayerShots(id, array);

  return [
    ...shots.map(shot =>
      formatShotVideo(
        { id, jersey, name, surname },
        { time: shot.time, videoTime: shot.videoTime },
        match,
      ),
    ),
  ].flat();
};

export const getPlayerShotOnGoalVideos = (player: IPlayer, array: IShot[], match: IMatch) => {
  const { id, jersey, name, surname } = player;
  const shots = getPlayerShots(id, array).filter(
    shot => shot.result === 'goal' || shot.result === 'saved',
  );

  return [
    ...shots.map(shot =>
      formatShotVideo(
        { id, jersey, name, surname },
        { time: shot.time, videoTime: shot.videoTime },
        match,
      ),
    ),
  ].flat();
};

export const getPlayerSlotShotVideos = (player: IPlayer, array: IShot[], match: IMatch) => {
  const { id, jersey, name, surname } = player;
  const shots = getPlayerShots(id, array).filter(shot => shot.inSlot);

  return [
    ...shots.map(shot =>
      formatShotVideo(
        { id, jersey, name, surname },
        { time: shot.time, videoTime: shot.videoTime },
        match,
      ),
    ),
  ].flat();
};

export const getPlayerMissedShotVideos = (player: IPlayer, array: IShot[], match: IMatch) => {
  const { id, jersey, name, surname } = player;
  const shots = getPlayerShots(id, array).filter(shot => shot.result === 'missed');

  return [
    ...shots.map(shot =>
      formatShotVideo(
        { id, jersey, name, surname },
        { time: shot.time, videoTime: shot.videoTime },
        match,
      ),
    ),
  ].flat();
};

export const getPlayerHighDangerShotVideos = (player: IPlayer, array: IShot[], match: IMatch) => {
  const { id, jersey, name, surname } = player;
  const shots = getPlayerShots(id, array).filter(shot => shot.shotDanger === 'HD');

  return [
    ...shots.map(shot =>
      formatShotVideo(
        { id, jersey, name, surname },
        { time: shot.time, videoTime: shot.videoTime },
        match,
      ),
    ),
  ].flat();
};

export const getPlayerLowDangerShotVideos = (player: IPlayer, array: IShot[], match: IMatch) => {
  const { id, jersey, name, surname } = player;
  const shots = getPlayerShots(id, array).filter(shot => shot.shotDanger === 'LD');

  return [
    ...shots.map(shot =>
      formatShotVideo(
        { id, jersey, name, surname },
        { time: shot.time, videoTime: shot.videoTime },
        match,
      ),
    ),
  ].flat();
};

export const getPlayerBlockedShotVideos = (player: IPlayer, array: IShot[], match: IMatch) => {
  const { id, jersey, name, surname } = player;
  const shots = getPlayerShots(id, array).filter(shot => shot.result === 'deflected');

  return [
    ...shots.map(shot =>
      formatShotVideo(
        { id, jersey, name, surname },
        { time: shot.time, videoTime: shot.videoTime },
        match,
      ),
    ),
  ].flat();
};

export const getPlayerFaceOffVideos = (player: IPlayer, array: IFaceOff[], match: IMatch) => {
  const { id, jersey, name, surname } = player;

  return [
    ...getPlayerFaceOff(id, array).map(faceOff =>
      formatFaceOffVideo(
        { id, jersey, name, surname },
        { time: faceOff.time, videoTime: faceOff.videoTime },
        match,
        `${faceOff.winnerId.id}` === id,
      ),
    ),
  ].flat();
};

export const parseVideoDvrResponse = (res: IVideoDvrResponse) =>
  Object.entries(res.data).reduce<IVideoDvrRecord>((acc, item) => {
    const [key, videoInfos] = item;

    acc[key] = videoInfos.map(videoInfo => ({
      ...videoInfo,
      channelId: key,
    }));

    return acc;
  }, {});
