import { FC, useEffect } from 'react';

import { useVideoDVR } from '../../Hooks/useVideo';
import { IVideo } from '../../Types';

const VIDEO_IFRAME_ID = 'video-file-iframe';

interface IVideoFrameDVRProps {
  video: IVideo;
}

const VideoFrameDVR: FC<IVideoFrameDVRProps> = ({ video }) => {
  const { html, key, errorEvent } = useVideoDVR(video);

  useEffect(() => {
    const setIframeContent = () => {
      const currentIframe = document.getElementById(VIDEO_IFRAME_ID) as HTMLIFrameElement;

      const iframeDocument = currentIframe.contentDocument;
      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(html || '');
        iframeDocument.close();
      }
    };
    if (!errorEvent) {
      setIframeContent();
    }
  }, [html, key, errorEvent]);

  if (errorEvent) {
    return (
      <div style={{ color: 'white', textAlign: 'center', fontSize: '32px' }}>
        Video této události aktuálně není dostupné. K dispozici bude po skončení zápasu.
      </div>
    );
  }

  return (
    <iframe
      key={key}
      id={VIDEO_IFRAME_ID}
      name={VIDEO_IFRAME_ID}
      title='videoplayer'
      width='100%'
      height='100%'
      allowFullScreen
      style={{ border: 0 }}
    ></iframe>
  );
};

export default VideoFrameDVR;
